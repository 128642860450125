@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: "Ciutadella";
	src: url(./assets/fonts/Ciutadella-Regular.woff2) format("woff2");
}

body {
	margin: 0;
	font-family: "Ciutadella", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
